import React from "react"
import { Link, graphql } from "gatsby"
// import Bio from "../components/bio"
import Layout from "../components/layout"
import Head from "../components/head"
import { rhythm, scale } from "../utils/typography"
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
// import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from "constants";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulPost
    
    const siteTitle =<Link style={{ boxShadow: 'none', textDecoration:'underline', fontWeight:'bold', fontSize:`1.5rem` }} to={'/'}>Back</Link>

    const { previous, next } = this.props.pageContext

    const options = {
      renderNode:{
        "embedded-asset-block": (node) =>{
          const alt = node.data.target.fields.title['en-US']
          const src = node.data.target.fields.file['en-US'].url
          const extension = src.split('.').pop(); 

          if (extension == "mp4") {
            return <video width="auto" height="500" align="center" controls><source src={src} type="video/mp4"/></video>

          } else {
            return <img alt={alt} src={src} />  
          }
          
        },
        "asset-hyperlink": (node)=>{
          const src = node.data.target.fields.file['en-US'].url;
          const text = node.content[0].value
          return <a href={src}>{text}</a>  
        },
        "entry-hyperlink":(node)=>{
          const src = node.data.target.fields.slug['en-US'];
          const text = node.content[0].value
          return <Link to={src}>{text}</Link>  
        }
      }
    }

    const truncate = (input) => input.length > 5 ? `${input.substring(0, 15)}...` : input;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Head title={(post.title)?post.title:null}/>
        <h1>{post.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.createdAt}
        </p>
        {documentToReactComponents(post.content.json, options)}
        {post.images?post.images.map(img=>{
           return <p> {img.description} <img src={img.fluid.src}/> </p>
        }):''}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        {/* <Bio /> */}

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.slug} rel="prev">
                ← {truncate(previous.title)}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="next">
                {truncate(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulPost( slug: { eq: $slug } ) {
      id
      title
      createdAt(formatString: "Do MMMM, YYYY")
      content{
        json
      }
    #images{
      #   description
      #   fluid{
      #     src
      #   }
      # }
    }
  }
`
