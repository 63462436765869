import React from "react"
import { Link } from "gatsby"
import './layout.scss'
import { rhythm, scale } from "../utils/typography"
// if (typeof window !== "undefined") {
//  require("smooth-scroll")('a[href*="#"]')
// }

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <span
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
              fontSize:'2.5rem'
            }}
            to={`/`}
          >
            {title}
          </Link>
        </span>
      )
    } else {
      header = (
        <span
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
            <a  style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `#C6281E`,
              fontSize:'1rem'
            }} href={"tel:+441442205808" } > <button type="button" style={{marginBottom:'20px'}} className="btn btn-success btn-cons">Call IT support</button>
          </a>
          <br></br> 
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </span>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer           
        style={{
            marginTop: rhythm(3.5),
            marginBop: 0,
          }}>
          © {new Date().getFullYear()} DBD Distribution Ltd. All rights reserved {` `}
          <a href="https://rarebit.dbd.co.uk/#/signin">eSign</a>
        </footer>
        <br></br>
        { (location.pathname !== rootPath && location.pathname !== '/search')?<a style={{
              color: `#C6281E`,
            }} href="javascript:window.scrollTo({ top: 0, behavior: 'smooth' });">Scroll back to top</a>:null}
      </div>
    )
  }
}

export default Layout
